<template>
    <div>
  <Summary />
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import Summary from '../../customComponents/Summary.vue'

export default defineComponent({
    setup() {
    },
    components: { Summary }
})
</script>
