<template>
    <div>
  <h1>Error</h1>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
    },
    components: { 
        
     }
})
</script>
